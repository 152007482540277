import React, { useEffect, useState } from "react"
import {
  Container,
  TitleSub,
  TitleMain,
  FlexBox,
  DescriptionBox2,
  DescriptionBox3,
  TitleHolder,
  DescriptionBoxHolder,
  ContainerMobile,
  ImageGroup1,
  DescriptionBox,
  Title,
  Description,
  ExampleImg,
} from "../../../ToolsStyles/HowItWorksStyeled"
import Step1 from "../../Assets/HowToImage_Step_1_pdftopng.png"
import Step2 from "../../Assets/pdf_html_2.png"
import Step3 from "../../Assets/HowToImage_Step_3_pdftopng.png"
import Group1 from "../../../merge/MergeAssets/Group1.svg"
import Group2 from "../../../merge/MergeAssets/Group2.svg"
import Group3 from "../../../merge/MergeAssets/Group3.svg"
import ActiveGroupOne from "../../../merge/MergeAssets/GroupActiveOne.svg"
import ActiveGroupTwo from "../../../merge/MergeAssets/GroupActiveTwo.svg"
import GroupActiveThree from "../../../merge/MergeAssets/GroupActiveThree.svg"

const HowItWorksMobile = () => {
  const [IsActive, setIsActive] = useState(1)

  useEffect(() => {
    window.clicked = false
    const intervalId = setInterval(() => {
      if (!window.clicked) {
        setIsActive(prevActive => {
          const newActive = prevActive === 3 ? 1 : prevActive + 1
          return newActive
        })
      } else {
        window.clicked = false
      }
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const ActiveTabOne = e => {
    setIsActive(1)
    window.clicked = true
  }

  const ActiveTabTwo = () => {
    setIsActive(2)
    window.clicked = true
  }

  const ActiveTabThree = () => {
    setIsActive(3)
    window.clicked = true
  }

  return (
    <ContainerMobile>
      <FlexBox>
        <DescriptionBoxHolder>
          <DescriptionBox>
            <ImageGroup1 src={Group1} />

            <Title ActiveState={IsActive === 1}>{"Upload PDF"}</Title>
            <Description>
              Upload the PDF that you want to convert using the upload button,
              or drag and drop the file into window.
            </Description>
          </DescriptionBox>
          <ExampleImg src={Step1} alt="example of how to upload file" />
          <DescriptionBox>
            <ImageGroup1 src={Group2} />

            <Title ActiveState={IsActive === 2}>Convert PDF to HTML</Title>
            <Description>
              Follow the on-screen prompts to convert your PDF.
            </Description>
          </DescriptionBox>
          <ExampleImg src={Step2} alt="example of how to merge file" />

          <DescriptionBox>
            <ImageGroup1 src={Group3} />

            <Title ActiveState={IsActive === 3}>Download!</Title>
            <Description>Download your new HTML file!</Description>
          </DescriptionBox>
          <ExampleImg src={Step3} alt="example of how to download file" />
        </DescriptionBoxHolder>
      </FlexBox>
    </ContainerMobile>
  )
}

export default HowItWorksMobile
