import React from "react"
import styled from "styled-components"

const Title = styled.h2`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #425466;
`
const ToolH2tag = props => {
  return <Title>{props.children} </Title>
}

export default ToolH2tag
